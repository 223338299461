<div class="gantt-container">
  <!-- Header -->
  <div class="gantt-header">
    <div class="gantt-header-item">Frac Crew</div>
    <div class="gantt-header-dates">
      <div *ngFor="let month of months" class="gantt-month" [style.width.px]="month.width">
        {{ month.name }}
      </div>
    </div>
  </div>

  <!-- Body -->
  <div class="gantt-body">
    <div *ngFor="let area of data" class="gantt-row">
      <div class="gantt-area-name">{{ area.fracCrew }}</div>
      <div class="gantt-area-bars">
        <div *ngFor="let pad of area.pads" class="gantt-bar"
             [style.width.px]="getBarWidth(pad.Start_Date, pad.Stop_Date)"
             [style.left.px]="getBarLeftPosition(pad.Start_Date)"
             [style.top.px]="getBarTopPosition(pad, area.pads)"
             [style.background]="pad.Fulfillment === 'Fulfilled' ? 'green' : 'red'"
             (mousemove)="showTooltip($event, pad)"
             (mouseout)="hideTooltip()">
          {{ pad.Pad_Name }}
        </div>
      </div>
    </div>
  </div>
  
  

<!-- Tooltip -->
<div #tooltip class="tooltip"></div>
