
      <div class="navbar-content">
        <div class="info">
          <app-help-button></app-help-button>
        </div>
        <div class="logo-title-container">
          <div class="logo-container">
            <img width="42" height="46" src="https://upload.wikimedia.org/wikipedia/commons/8/86/Chevron_Logo.svg" alt="Chevron Logo">
          </div>
          <div class="title-container">Water Optimizer</div>
        </div>
        <div class="login-container">
          <app-logout></app-logout>
        </div>
      </div>
   
