import { Component, OnInit, OnChanges, SimpleChanges, Input, ViewChild, ElementRef } from '@angular/core';
import * as d3 from 'd3';
import { DatePipe } from '@angular/common';
import { SharedDataService } from '../../services/shareddata.service';

interface Pad {
  Pad_Name: string;
  Start_Date: string;
  Stop_Date: string;
  Per_day_req: number;
  Fulfillment: string;
  Type_of_water: string;
  fracCrew: string;
}

@Component({
  selector: 'app-gantt-chart',
  templateUrl: './gantt-chart.component.html',
  styleUrls: ['./gantt-chart.component.css'],
  providers: [DatePipe]
})
export class GanttChartComponent implements OnInit, OnChanges {
  @Input() dataSource!: 'base' | 'gen'; // Input property to receive data source
  public data: { fracCrew: string; pads: Pad[] }[] = [];
  public months: { name: string, width: number }[] = [];
  public startDate!: Date;
  public endDate!: Date;
  public labelWidth: number = 20;

  @ViewChild('tooltip') tooltip: ElementRef | undefined;

  constructor(private datePipe: DatePipe, private sharedDataService: SharedDataService) {}

  ngOnInit(): void {
    // Initial setup (will be called only once)
    this.initializeChartData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Detect change in the dataSource input
    if (changes['dataSource'] && !changes['dataSource'].firstChange) {
      this.initializeChartData(); // Re-initialize data when dataSource changes
    }
  }

  private initializeChartData(): void {
    let padReqData: Pad[] = [];

    // Fetch data based on the dataSource value
    if (this.dataSource === 'base') {
      padReqData = this.sharedDataService.getBasePadReqData();
      console.log("padReqData",padReqData);
      
    } else if (this.dataSource === 'gen') {
      padReqData = this.sharedDataService.getPadReqData();
      console.log("genpadReqData",padReqData);
      
    }
    
    if (padReqData && padReqData.length > 0) {
      this.mapPadReqData(padReqData);
      this.calculateDateRange();
      this.setCalendar();
    } else {
      console.error('Pad requirements data is empty. Ensure it is set in SharedDataService.');
    }
  }

  private mapPadReqData(padReq: Pad[]): void {
    const groupedData = d3.group(padReq, (d) => d.fracCrew);
    this.data = Array.from(groupedData, ([fracCrew, pads]) => ({ fracCrew, pads }));
  }

  private calculateDateRange(): void {
    const allPads = this.data.flatMap(devArea => devArea.pads);
    const minStartDate = d3.min(allPads, d => new Date(d.Start_Date))!;
    const maxStopDate = d3.max(allPads, d => new Date(d.Stop_Date))!;

    this.startDate = minStartDate;
    this.endDate = maxStopDate;
  }

  private setCalendar(): void {
    let currentDate = new Date(this.startDate);
    this.months = [];

    while (currentDate <= this.endDate) {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth();
      const daysInMonth = new Date(year, month + 1, 0).getDate();
      const monthName = this.datePipe.transform(currentDate, 'MMM yyyy')!;

      const monthWidth = daysInMonth * this.labelWidth;

      this.months.push({ name: monthName, width: monthWidth });

      currentDate.setMonth(currentDate.getMonth() + 1);
    }
  }

  public getBarWidth(startDate: string, endDate: string): number {
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    const dayWidth = this.labelWidth;

    return ((end - start) / (1000 * 60 * 60 * 24) + 1) * dayWidth;
  }

  public getBarLeftPosition(startDate: string): number {
    const start = new Date(this.startDate).getTime();
    const barStart = new Date(startDate).getTime();
    const dayWidth = this.labelWidth;

    return ((barStart - start) / (1000 * 60 * 60 * 24)) * dayWidth;
  }

  public getBarTopPosition(pad: Pad, pads: Pad[]): number {
    const barHeight = 30; 
    const barSpacing = 1; 
    const currentStart = new Date(pad.Start_Date).getTime();
    const currentEnd = new Date(pad.Stop_Date).getTime();
  
    const rowOccupied: { start: number; end: number }[][] = [];
  
    for (let rowIndex = 0; ; rowIndex++) {
      if (!rowOccupied[rowIndex]) {
        rowOccupied[rowIndex] = [];
      }
  
      const isRowFree = !rowOccupied[rowIndex].some(
        (existing) => currentStart < existing.end && currentEnd > existing.start
      );
  
      if (isRowFree) {
        rowOccupied[rowIndex].push({ start: currentStart, end: currentEnd });
        return rowIndex * (barHeight + barSpacing); 
      }
    }
  }

  ngAfterViewInit(): void {
    this.data.forEach((area, index) => {
      const maxRows = this.calculateMaxRows(area.pads);
      const rowElement = document.querySelectorAll('.gantt-row')[index] as HTMLElement;
      if (rowElement) {
        rowElement.style.height = `${maxRows * (30 + 1)}px`; 
      }
    });
  }

  private calculateMaxRows(pads: Pad[]): number {
    const rowOccupied: { start: number; end: number }[][] = [];
    const barHeight = 30;
    const barSpacing = 1;
  
    pads.forEach((pad) => {
      const currentStart = new Date(pad.Start_Date).getTime();
      const currentEnd = new Date(pad.Stop_Date).getTime();
  
      for (let rowIndex = 0; ; rowIndex++) {
        if (!rowOccupied[rowIndex]) {
          rowOccupied[rowIndex] = [];
        }
  
        const isRowFree = !rowOccupied[rowIndex].some(
          (existing) => currentStart < existing.end && currentEnd > existing.start
        );
  
        if (isRowFree) {
          rowOccupied[rowIndex].push({ start: currentStart, end: currentEnd });
          break;
        }
      }
    });
  
    return rowOccupied.length;
  }

  public getTooltipInfo(pad: Pad): string {
    return `Pad: ${pad.Pad_Name}\nStart: ${pad.Start_Date}\nStop: ${pad.Stop_Date}\nPer Day Req.: ${pad.Per_day_req}\nFulfillment: ${pad.Fulfillment}\nWater Type: ${pad.Type_of_water}`;
  }

  public showTooltip(event: MouseEvent, pad: Pad): void {
    const tooltip = this.tooltip?.nativeElement as HTMLElement;
    tooltip.innerHTML = this.getTooltipInfo(pad);
    tooltip.style.display = 'block';
    tooltip.style.left = `${event.pageX}px`;
    tooltip.style.top = `${event.pageY + 15}px`;
  }

  public hideTooltip(): void {
    const tooltip = this.tooltip?.nativeElement as HTMLElement;
    tooltip.style.display = 'none';
  }
}
