import { Component,OnInit, ViewChild} from '@angular/core';
import { SharedDataService } from '../../services/shareddata.service';
import { MessageService } from 'primeng/api'; 
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-generator',
  templateUrl: './generator.component.html',
  styleUrls: ['./generator.component.css'],
  providers: [MessageService] // Include for toast messages
})
export class GeneratorComponent implements OnInit {
  generatorData: any[] = [];
  filteredData: any[] = [];
  paginatedData: any[] = [];
  isDialogVisible = false;
  showResultSection: boolean = false;
  selectedRow: any = null;
  currentSortField: string = ''; 
  sortOrder: 'asc' | 'desc' = 'asc';

  uniqueHexCodes: any[] = [];
  uniqueFulfillmentStatuses: any[] = [];
  uniqueCosts: any[] = [];

  selectedHexCode: string | null = null;
  selectedSuccess: string | null = null;
  selectedRunCost: string | null = null;
  
  loading: boolean = false;
 


  padReq: any[] = [];
  pondSummary: any[] = [];
  transactionAll: any[] = [];
  sequenceTable:any[] = [];
  operationTable:any[] = [];
  connectionTable:any[] = [];
  capacityTable:any[] = [];

  // Dropdown options for Pad Req Table
  padOperatorOptions: any[] =[];
  padNameOptions: any[] = [];
  startDateOptions: any[] = [];
  stopDateOptions: any[] =[];
  perDayReqOptions: any[] = [];
  typeOfWaterOptions: any[] = [];
  fulfillmentOptions: any[] = [];

  // Dropdown options for Pond Summary Table
  pondDateOptions: any[] = [];
  pondOptions: any[] = [];
  pondTypeOptions: any[] = [];
  pondQtyOptions: any[] = [];
  pondInOptions: any[] = [];
  pondOutOptions: any[] = [];
  pondEvapOptions: any[] = [];
  pondMinOptions: any[] = [];
  pondMaxOptions: any[] = [];

  // Dropdown options for Transaction All Table
  dateOptions:any[] =[];
  sourceOptions: any[] = [];
  destinationOptions: any[] = [];
  qtyOptions: any[] = [];
  typeTransactionOptions: any[] = [];
  costOptions: any[] = [];

  // Selected values for filtering
  selectedOperator: any = null;
  selectedPadName: any = null;
  selectedStartDate: any = null;
  selectedStopDate: any = null;
  selectedPerDayReq: any = null;
  selectedTypeOfWater: any = null;
  selectedFulfillment: any = null;

  selectedPondDate: any = null;
  selectedPond: any = null;
  selectedPondType: any = null;
  selectedPondQty: any = null;
  selectedPondIn: any = null;
  selectedPondOut: any = null;
  selectedPondMin: any = null;
  selectedPondMax: any = null;
  selectedPondEvap: any = null;

  selectedDate: any = null;
  selectedSource: any = null;
  selectedDestination: any = null;
  selectedQty: any = null;
  selectedTransactionType: any = null;
  selectedCost: any = null;

  filteredPadReq: any[] = [];
  filteredPondSummary: any[] = [];
  filteredTransactionAll: any[] = [];
  filteredSequenceTable: any[] = [];
  filteredOperationTable: any[] = [];
  filteredConnectionTable: any[] = [];
  filteredCapacityTable: any[] = [];

  rowsPerPage = 5;
  currentPage = 0;
  
  runName: string | null = null;
  runId: string | null = null;
  

  constructor(
    private sharedDataService: SharedDataService,
    private messageService: MessageService,private router: Router,
  ) {}

  ngOnInit() {
    this.sharedDataService.getRunDetails().runName.subscribe((runName) => {
      this.runName = runName;
    });
    this.sharedDataService.getRunDetails().runId.subscribe((runId) => {
      this.runId = runId;
    });
    const genData = {
      RunName: this.runName as string, 
      RunId: this.runId as string,
    };
    console.log("genData", genData);
  
    this.sharedDataService.getGenerator(genData).subscribe(
      (data) => {
        console.log('Raw response from backend:', data);
  
        
        this.generatorData = Object.values(data);
  
        if (Array.isArray(this.generatorData)) {
          this.filteredData = [...this.generatorData];
          this.updateUniqueValues();
          this.paginate({ first: 0, rows: this.rowsPerPage });
          this.isDialogVisible = true;
        } else {
          console.error('Expected array, but got:', this.generatorData);
        }
      },
      (error) => {
        console.error('Error fetching generator data', error);
      }
    );
  }

  // getGeneratorData() { 
  //   const genData = {
  //     RunName: this.runName as string, 
  //     RunId: this.runId as string,
  //   };
  //   console.log("genData", genData);
  
  //   this.sharedDataService.getGenerator(genData).subscribe(
  //     (data) => {
  //       console.log('Raw response from backend:', data);
  
        
  //       this.generatorData = Object.values(data);
  
  //       if (Array.isArray(this.generatorData)) {
  //         this.filteredData = [...this.generatorData];
  //         this.updateUniqueValues();
  //         this.paginate({ first: 0, rows: this.rowsPerPage });
  //         this.isDialogVisible = true;
  //       } else {
  //         console.error('Expected array, but got:', this.generatorData);
  //       }
  //     },
  //     (error) => {
  //       console.error('Error fetching generator data', error);
  //     }
  //   );
  // }

  updateUniqueValues() {
    this.uniqueHexCodes = this.getUniqueGeneratorValues('Scenario_Hex_Code');
    this.uniqueFulfillmentStatuses = this.getUniqueGeneratorValues('Success');
    this.uniqueCosts = this.getUniqueGeneratorValues('Cost');
  }
  sortData(field: string): void {
    if (this.currentSortField === field) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.currentSortField = field;
      this.sortOrder = 'asc';
    }

    this.filteredData.sort((a, b) => {
      const valueA = a[field];
      const valueB = b[field];

      if (valueA < valueB) return this.sortOrder === 'asc' ? -1 : 1;
      if (valueA > valueB) return this.sortOrder === 'asc' ? 1 : -1;
      return 0;
    });

    this.paginate({ first: 0, rows: this.rowsPerPage });
  }

  getUniqueGeneratorValues(column: string) {
    const uniqueValues = [...new Set(this.generatorData.map(item => item[column]))];
    return uniqueValues.map(value => ({ label: value, value }));
  }

  filterGeneratorData() {
    this.filteredData = this.generatorData.filter(item => {
      return (
        (!this.selectedHexCode || item.Scenario_Hex_Code === this.selectedHexCode) &&
        (!this.selectedSuccess|| item.Success === this.selectedSuccess) &&
        (!this.selectedRunCost || item.Cost === this.selectedRunCost)
      );
    });
    this.paginate({ first: 0, rows: this.rowsPerPage });
  }

  paginate(event: any) {
    const start = event.first;
    const end = start + event.rows;
    this.paginatedData = this.filteredData.slice(start, end);
  }

  selectRow(row: any) {
    this.selectedRow = row;
  }
  navToCalculateTab(){
    // this.sharedDataService.sequenceData = this.sharedDataService.getPadReqData();
    // this.sharedDataService.operationData = this.sharedDataService.getPadReqData();
    this.sharedDataService.connectionData = this.sharedDataService.getWaterFlowData();
    this.sharedDataService.capacityData = this.sharedDataService.getPondCapData();
    this.router.navigate(['/calculate-tab']);
   
  }

  showResult() {
    if (this.selectedRow) {
      const dataToSend = {
        ...this.selectedRow,
        RunName: this.runName,
        RunId: this.runId,
      };

      

      console.log('Data to send:', dataToSend);
      console.log(this.selectRow,"selectedrow");
      
      this.sharedDataService.sendSelectedGeneratorDetails(dataToSend).subscribe(
        (data) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Data sent successfully!' });
          console.log('Response from backend:', data);
          const response = data.response; 
          if (response) {
            this.padReq = response.Output_Pad_Requirements || []; 
            this.sharedDataService.setPadReqData(this.padReq);
            this.pondSummary = response.Output_Pond_Summary || []; 
            this.transactionAll = response.Output_Transaction_all || [];
            this.sequenceTable = response.Input_Pad_details || []; 
            this.operationTable = response.Input_Pond_Operations || []; 
            this.sharedDataService.setPondOpData(this.operationTable)
            this.connectionTable = response.Input_Connections_table || []; 
            this.sharedDataService.setWaterFlowData(this.connectionTable)
            this.capacityTable = response.Input_Pond_Initial_Capacity || [];
            this.sharedDataService.setPondCapData(this.capacityTable)
            console.log("gen",this.padReq);
            

            this.populateDropdownOptions();
            
            this.filteredPadReq = [...this.padReq];
            this.filteredPondSummary = [...this.pondSummary];
            this.filteredTransactionAll = [...this.transactionAll];
            this.filteredSequenceTable = [...this.sequenceTable];
            this.filteredOperationTable = [...this.operationTable];
            this.filteredConnectionTable = [...this.connectionTable];
            this.filteredCapacityTable = [...this.capacityTable];

          } else {
            console.error('No response received from backend.');
          }
        
        },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to send data.' });
          console.error('Error sending data:', error);
        }
      );
    }
    this.isDialogVisible = false;
    this.showResultSection = true;
    console.log("Testing :",this.isDialogVisible,this.showResultSection)
  }
  
  exportToExcel() {
    
    const wb = XLSX.utils.book_new();
    const processedSequenceTable = this.sequenceTable.map(({isEditing,Buffer_days,Date_Diff,Pad_Key,fw,bw,tpw,rpw, ...rest }) => rest);

    const padReqWorksheet = XLSX.utils.json_to_sheet(this.padReq);
    const pondSummaryWorksheet = XLSX.utils.json_to_sheet(this.pondSummary);
    const transactionAllWorksheet = XLSX.utils.json_to_sheet(this.transactionAll);
    const sequenceTableWorksheet = XLSX.utils.json_to_sheet(processedSequenceTable);
    const operationTableWorksheet = XLSX.utils.json_to_sheet(this.operationTable);
    const connectionTableWorksheet = XLSX.utils.json_to_sheet(this.connectionTable);
    const capacityTableWorksheet = XLSX.utils.json_to_sheet(this.capacityTable);

    
    XLSX.utils.book_append_sheet(wb, padReqWorksheet, 'Result_Pad_Req');
    XLSX.utils.book_append_sheet(wb, pondSummaryWorksheet, 'Result_Pond_Summary');
    XLSX.utils.book_append_sheet(wb, transactionAllWorksheet, 'Result_Transaction_All');
    XLSX.utils.book_append_sheet(wb, sequenceTableWorksheet, 'Input_Sequence_Table');
    XLSX.utils.book_append_sheet(wb, operationTableWorksheet, 'Input_Operation_Table');
    XLSX.utils.book_append_sheet(wb, connectionTableWorksheet, 'Input_Connection_Table');
    XLSX.utils.book_append_sheet(wb, capacityTableWorksheet, 'Input_Capacity_Table');

    
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    
    const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    saveAs(data, 'generator_data.xlsx');
  }
  
  populateDropdownOptions() {
    this.padOperatorOptions = this.getUniqueValues(this.padReq, 'fracCrew');
    this.padNameOptions = this.getUniqueValues(this.padReq, 'Pad_Name');
    this.startDateOptions = this.getUniqueValues(this.padReq, 'Start_Date');
    this.stopDateOptions = this.getUniqueValues(this.padReq, 'Stop_Date');
    this.perDayReqOptions = this.getUniqueValues(this.padReq, 'Per_day_req');
    this.typeOfWaterOptions = this.getUniqueValues(this.padReq, 'Type_of_water');
    this.fulfillmentOptions = this.getUniqueValues(this.padReq, 'Fulfillment');

    this.pondDateOptions = this.getUniqueValues(this.pondSummary, 'Date');
    this.pondOptions = this.getUniqueValues(this.pondSummary, 'Pond');
    this.pondTypeOptions = this.getUniqueValues(this.pondSummary, 'Type');
    this.pondQtyOptions = this.getUniqueValues(this.pondSummary, 'Qty');
    this.pondInOptions = this.getUniqueValues(this.pondSummary, 'In');
    this.pondOutOptions = this.getUniqueValues(this.pondSummary, 'Out');
    this.pondEvapOptions = this.getUniqueValues(this.pondSummary, 'Evap');
    this.pondMinOptions = this.getUniqueValues(this.pondSummary, 'Min');
    this.pondMaxOptions = this.getUniqueValues(this.pondSummary, 'Max');

    this.dateOptions = this.getUniqueValues(this.transactionAll, 'Date');
    this.sourceOptions = this.getUniqueValues(this.transactionAll, 'Source');
    this.destinationOptions = this.getUniqueValues(this.transactionAll, 'Destination');
    this.qtyOptions = this.getUniqueValues(this.transactionAll, 'Qty');
    this.typeTransactionOptions = this.getUniqueValues(this.transactionAll, 'Type');
    this.costOptions = this.getUniqueValues(this.transactionAll, 'Cost');
  }

  getUniqueValues(data: any[], key: string) {
    return [...new Set(data.map(item => item[key]))].map(value => ({ label: value, value }));
  }

  filterData() {
    this.filteredPadReq = this.padReq.filter(item => {
      return (
        (this.selectedOperator ? item['fracCrew'] === this.selectedOperator : true) &&
        (this.selectedPadName ? item['Pad_Name'] === this.selectedPadName : true) &&
        (this.selectedStartDate ? item['Start_Date'] === this.selectedStartDate : true) &&
        (this.selectedStopDate ? item['Stop_Date'] === this.selectedStopDate : true) &&
        (this.selectedPerDayReq ? item['Per_day_req'] === this.selectedPerDayReq : true) &&
        (this.selectedTypeOfWater ? item['Type_of_water'] === this.selectedTypeOfWater : true) &&
        (this.selectedFulfillment ? item['Fulfillment'] === this.selectedFulfillment : true)
      );
    });

    this.filteredPondSummary = this.pondSummary.filter(item => {
      return (
        
        (this.selectedPondDate ? item['Date'] === this.selectedPondDate : true) &&
        (this.selectedPond ? item['Pond'] === this.selectedPond : true) &&
        (this.selectedPondType ? item['Type'] === this.selectedPondType : true) &&
        (this.selectedPondQty ? item['Qty'] === this.selectedPondQty : true) &&
        (this.selectedPondIn ? item['In'] === this.selectedPondIn : true) &&
        (this.selectedPondOut ? item['Out'] === this.selectedPondOut : true) &&
        (this.selectedPondEvap ? item['Evap'] === this.selectedPondEvap : true) &&
        (this.selectedPondMin ? item['Min'] === this.selectedPondMin : true) &&
        (this.selectedPondMax ? item['Max'] === this.selectedPondMax : true)
      );
    });

    this.filteredTransactionAll = this.transactionAll.filter(item => {
      return (
        (this.selectedDate ? item['Date'] === this.selectedDate : true) &&
        (this.selectedSource ? item['Source'] === this.selectedSource : true) &&
        (this.selectedDestination ? item['Destination'] === this.selectedDestination : true) &&
        (this.selectedQty ? item['Qty'] === this.selectedQty : true) &&
        (this.selectedTransactionType ? item['Type'] === this.selectedTransactionType : true) &&
        (this.selectedCost ? item['Cost'] === this.selectedCost : true) 
      );
    });
  }
  goBackToGeneratorDetails(): void {
    this.isDialogVisible = true;
    this.showResultSection = false;
  }

  clearPadNameFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedPadName = null; 
    this.filterData(); 
  }

  clearStartDateFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedStartDate = null; 
    this.filterData(); 
  }

  clearStopDateFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedStopDate = null;
    this.filterData(); 
  }
  
  clearPerDayReqFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedPerDayReq = null; 
    this.filterData(); 
  }
  
  clearTypeOfWaterFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedTypeOfWater = null; 
    this.filterData(); 
  }
  
  clearFulFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedFulfillment = null; 
    this.filterData(); 
  }

  clearPondFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedPond = null;
    this.filterData();
  }

  clearPondTypeFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedPondType = null;
    this.filterData();
  }
  clearPondDateFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedPondDate = null;
    this.filterData();
  }
  clearPondEvapFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedPondEvap = null;
    this.filterData();
  }
  clearPondQtyFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedPondQty = null;
    this.filterData();
  }
  clearPondInFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedPondIn = null;
    this.filterData();
  }
  clearPondOutFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedPondOut = null;
    this.filterData();
  }
  clearPondMinFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedPondMin = null;
    this.filterData();
  }
  clearPondMaxFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedPondMax = null;
    this.filterData();
  }

  clearDateFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedDate = null;
    this.filterData();
  }

  clearSourceFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedSource = null;
    this.filterData();
  }

  clearDestinationFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedDestination = null;
    this.filterData();
  }

  clearQtyFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedQty = null;
    this.filterData();
  }

  clearTransactionTypeFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedTransactionType = null;
    this.filterData();
  }
  clearCostFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedCost = null;
    this.filterData();
  }
}
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';