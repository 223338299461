import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private sequenceKey = 'sequenceData';
  private operationKey = 'operationData';
  private connectionKey = 'connectionData';
  private capacityKey = 'capacityData';
  
 
  private validationFlag: any[] =[];
  private validationSummary: any[] = [];
  private validationErrors: any[] = [];
  
  private basePadReqData: any[] = [];
  private padReqData: any[] = [];
  private pondCapData: any[] = [];
  private pondOpData: any[] = [];
  private waterFlowData: any[] = [];
 
  private padReq: any[] = [];
  private transactionAll: any[] = [];
  private pondSummary: any[] = [];
  private sequenceTable: any[] = [];
  private operationTable: any[] = [];
  private connectionTable: any[] = [];
  private capacityTable: any[] = [];


  private username: string = ''; 
  private runNameSource = new BehaviorSubject<string | null>(null);
  private runIdSource = new BehaviorSubject<string | null>(null);
  private selectedRunSource = new BehaviorSubject<any | null>(null);
  
  private runDataSource = new BehaviorSubject<any[]>([]); 

  private InputSeqData: any[] = [];
  private InputOpData: any[] = [];
  private InputConnData: any[] = [];
  private InputCapData: any[] = [];
  private selectedRunName: string | null = null;

  private apiUrl = environment.WebApiURL;

  constructor(private http: HttpClient) {}
 
  setInputSeqData(data:any[]) :void{
    this.InputSeqData=data;
  }
  getInputSeqData(): any[] {
    return this.InputSeqData;
  }
  setInputOpData(data:any[]) : void {
    this.InputOpData=data;
  }
  getInputOpData():any[]{
    return this.InputOpData;
  }
  setInputConnData(data: any[]): void {
    this.InputConnData = data;
  }
  getInputConnData(): any[] {
    return this.InputConnData;
  }
  setInputCapData(data: any[]): void {
    this.InputCapData = data;
  }
  getInputCapData(): any[] {
    return this.InputCapData;
  }
  setSelectedRunName(name: string): void {
    this.selectedRunName = name;
  }
  getSelectedRunName(): string | null {
    return this.selectedRunName;
  }
  get sequenceData(): any[] {
    const data = localStorage.getItem(this.sequenceKey);
    return data ? JSON.parse(data) : [];
  }

  set sequenceData(data: any[]) {
    localStorage.setItem(this.sequenceKey, JSON.stringify(data));
  }

  get operationData(): any[] {
    const data = localStorage.getItem(this.operationKey);
    return data ? JSON.parse(data) : [];
  }

  set operationData(data: any[]) {
    localStorage.setItem(this.operationKey, JSON.stringify(data));
  }

  get connectionData(): any[] {
    const data = localStorage.getItem(this.connectionKey);
    return data ? JSON.parse(data) : [];
  }

  set connectionData(data: any[]) {
    localStorage.setItem(this.connectionKey, JSON.stringify(data));
  }

  get capacityData(): any[] {
    const data = localStorage.getItem(this.capacityKey);
    return data ? JSON.parse(data) : [];
  }

  set capacityData(data: any[]) {
    localStorage.setItem(this.capacityKey, JSON.stringify(data));
  }

  
  setValidationData(flag:any[],summary: any[], errors: any[]): void {
    this.validationFlag= flag;
    this.validationSummary = summary;
    this.validationErrors = errors;
    console.log(summary,errors,flag,"aboutError");
    
  }

  getValidationFlag(): any[] {
    return this.validationFlag;
  }

  getValidationSummary(): any[] {
    return this.validationSummary;
  }

  getValidationErrors(): any[] {
    return this.validationErrors;
  }
  
  setPadReqData(data:any[]):void{
    this.padReqData=data;
  }
  getPadReqData() : any[] {
    return this.padReqData;
  }
  setBasePadReqData(data:any[]):void{
    this.basePadReqData=data;
  }
  getBasePadReqData() : any[] {
    return this.basePadReqData;
  }

  setPondCapData(data:any[]):void{
    this.pondCapData=data;
  }
  getPondCapData() : any[] {
    return this.pondCapData
  }
  setPondOpData(data:any[]):void{
    this.pondOpData=data;
  }
  getPondOpData() : any[]{
    return this.pondOpData
  }
  setWaterFlowData(data:any[]):void{
    this.waterFlowData=data;
  }
  getWaterFlowData():any[]{
    return this.waterFlowData
  }

  getPadReq(): any[] {
    return this.padReq;
  }

  getTransactionAll(): any[] {
    return this.transactionAll;
  }

  getPondSummary(): any[] {
    return this.pondSummary;
  }
  getSequenceTable(): any[] {
    return this.sequenceTable;
  }
  getOperationTable(): any[] {
    return this.operationTable;
  }
  getConnectionTable(): any[] {
    return this.connectionTable;
  }
  getCapacityTable(): any[] {
    return this.capacityTable;
  }

 
  setEvaluationData(padReq: any[], transactionAll: any[], pondSummary: any[], sequenceTable:any[], operationTable:any[], connectionTable:any[],capacityTable:any[],): void {
    this.padReq = padReq;
    this.transactionAll = transactionAll;
    this.pondSummary = pondSummary;
    this.sequenceTable = sequenceTable;
    this.operationTable = operationTable;
    this.connectionTable = connectionTable;
    this.capacityTable = capacityTable;
    
  }
   
   get getUsername(): string {
    return this.username;
  }

  set setUsername(username: string) {
    this.username = username;
  }
  
    
  setRunData(data: any[]): void {
    this.runDataSource.next(data);  
  }

  
  getRunData(): Observable<any[]> {
    return this.runDataSource.asObservable();  
  }
  setRunDetails(runName: string, runId: string): void {
    this.runNameSource.next(runName);
    this.runIdSource.next(runId);
  }

  getRunDetails(): { runName: Observable<string | null>; runId: Observable<string | null> } {
    return {
      runName: this.runNameSource.asObservable(),
      runId: this.runIdSource.asObservable(),
    };
  }

  setSelectedRun(run: any): void {
    this.selectedRunSource.next(run);
  }

  getSelectedRun(): Observable<any> {
    return this.selectedRunSource.asObservable();
  }

  sendTablesAndGetValidation(): Observable<any> {
    const requestData = {
      Pad_details: this.sequenceData,
      Pond_Operations: this.operationData,
      Connection_table: this.connectionData,
      Pond_Initial_Capacity: this.capacityData
    };
  
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  
    
    return this.http.post(`${this.apiUrl}/validate`, requestData, { headers });
  }

   sendUsernameData(username: string, timestamp: string,generatorFlag: boolean): Observable<any> {
    
    const requestBody = {
      username: this.username,  
      timestamp: new Date().toISOString(),  
      table_data: {  
          sequenceData: this.sequenceData,
          operationData: this.operationData,
          connectionData: this.connectionData,
          capacityData: this.capacityData
      },
      generatorFlag: generatorFlag
    };
    console.log("requestbody" , requestBody);
    
    
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  
    return this.http.post(`${this.apiUrl}/RunEvaluator`, requestBody, { headers });
  }
  sendRunGeneratorData(username: string,timestamp: string,generatorFlag: boolean): Observable<any> {
    const requestBody = {
      username: this.selectedRunName,  
      timestamp: new Date().toISOString(),  
      table_data: {  
        sequenceData: this.getInputSeqData(),
        operationData: this.getInputOpData(),
        connectionData: this.getInputConnData(),
        capacityData: this.getInputCapData()
      },
      generatorFlag: generatorFlag
    };
  
    console.log("Run Generator Request Body:", requestBody);
  
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  
    return this.http.post(`${this.apiUrl}/RunEvaluator`, requestBody, { headers });
  }
  

  
   
  sendSelectedRunDetails(selectedRun: any): Observable<any> {
    
    return this.http.post(`${this.apiUrl}/rundetails`, selectedRun);  
  }
  

 
  getStatusFlag(): Observable<any> {
    return this.http.get(`${this.apiUrl}/getStatusFlag`);
  }

  
  
  getGenerator(payload: { RunName: string; RunId: string }): Observable<any> {
    return this.http.post(`${this.apiUrl}/generator`, payload);
  }

  sendSelectedGeneratorDetails(selectedRun: any): Observable<any> {
    
    return this.http.post(`${this.apiUrl}/generatordetails`, selectedRun);  
  }
  
}
