
    <div [style.display]=" isDialogVisible ? 'block':'none'">
      <div class="table-header-container" style="display: flex; align-items: center; justify-content: space-between;">
        <h3 class="run-details-title"></h3>
        <div class="refresh-eval-button">
          <button pButton label="Results" (click)="showResult()" [disabled]="!selectedRow" styleClass="p-button-info" style="margin-right: 15px; font-size: 14px; height: 28px;" ></button>
        </div>
      </div>
        <table class="striped-table">
        <thead>
          <tr>
            <th style="background-color: white; width: 40px;"></th>
            <th style="background-color: #e4f6fd; color: black; text-align: center; border-bottom: none;">
              <span class="header-text">Hex Code</span>
            </th>
            <th style="background-color: #e4f6fd; color: black; text-align: center; border-bottom: none;">
              <span class="header-text">Success</span>
            </th>
            <th style="background-color: #e4f6fd; color: black; text-align: center; border-bottom: none; line-height: 1; cursor: pointer;" 
                (click)="sortData('Cost')">
              <span class="header-text">Cost</span>
              <span *ngIf="currentSortField === 'Cost'">
                <i *ngIf="sortOrder === 'asc'" class="pi pi-sort-amount-up"></i>
                <i *ngIf="sortOrder === 'desc'" class="pi pi-sort-amount-down"></i>
              </span>
            </th>
          </tr>
          <tr>
            <th style="background-color: white; width: 40px;"></th>
            <th style="background-color: #e4f6fd; text-align: center;">
              <p-dropdown 
                [options]="uniqueHexCodes" 
                [(ngModel)]="selectedHexCode" 
                placeholder="Select Code" 
                (onChange)="filterGeneratorData()" 
                [showClear]="true"
                class="status-dropdown">
              </p-dropdown>
            </th>
            <th style="background-color: #e4f6fd; text-align: center;">
              <p-dropdown 
                [options]="uniqueFulfillmentStatuses"
                [(ngModel)]="selectedSuccess" 
                placeholder="Filter by Success" 
                (onChange)="filterGeneratorData()"
                [showClear]="true"
                class="status-dropdown">
              </p-dropdown>
            </th>
            <th style="background-color: #e4f6fd; text-align: center;">
              <p-dropdown 
                [options]="uniqueCosts" 
                [(ngModel)]="selectedRunCost" 
                placeholder="Filter by Cost" 
                (onChange)="filterGeneratorData()"
                [showClear]="true"
                class="status-dropdown">
              </p-dropdown>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of paginatedData" [class.selected-row]="item === selectedRow">
            <td style="text-align: center; width: 40px; background-color: white;">
              <p-radioButton name="selectedRow" [value]="item" [(ngModel)]="selectedRow"></p-radioButton>
            </td>
            <td style="text-align: center; font-family: 'GothamNarrowBook', sans-serif !important; font-size: 11px !important;">{{ item.Scenario_Hex_Code }}</td>
            <td style="text-align: center; font-family: 'GothamNarrowBook', sans-serif !important; font-size: 11px !important;">{{ item.Success }}</td>
            <td style="text-align: center; font-family: 'GothamNarrowBook', sans-serif !important; font-size: 11px !important;">{{ "$" + item.Cost }}</td>
          </tr>
        </tbody>
        </table>
        <p-paginator [rows]="rowsPerPage" [totalRecords]="filteredData.length" (onPageChange)="paginate($event)"></p-paginator>
    </div>



<div [style.display]=" showResultSection ? 'block':'none'">
    <div class="refresh-eval-button" style="display: flex; gap: 10px; justify-content: end;">
      <button class="load" (click)="navToCalculateTab()">Load</button>
      <button class="custom-download" (click)="exportToExcel()">
        <i class="pi pi-download icon"></i>
      </button>
      <button class="custom-back-button" (click)="goBackToGeneratorDetails()">
        <i class="pi pi-arrow-left"></i> 
      </button> 
    </div>
    
        <p-tabView>
          <p-tabPanel header="Pad Requirement">
            <div>
              <p-table [value]="filteredPadReq" [scrollable] ="true" scrollHeight="350px" class="custom-table">
                
                
                <ng-template pTemplate="header">
                  <tr>
                    <th >
                      <div class="column-header">Frac Crew </div>
                      <p-dropdown class="header-dropdown" [options]="padOperatorOptions" [(ngModel)]="selectedOperator" (onChange)="filterData()"
                                  placeholder="Select Operator" [appendTo]="'body'" [showClear]="true" [filter]="true" filterPlaceholder="">
                      </p-dropdown>
                    </th>
                  
                    <th >
                      <div class="column-header">Pad Name </div>
                      <p-dropdown class="header-dropdown" [options]="padNameOptions" [(ngModel)]="selectedPadName" (onChange)="filterData()"
                                  placeholder="Select Pad Name" [appendTo]="'body'" [showClear]="true" [filter]="true" filterPlaceholder="">
                      </p-dropdown>
                    </th>
          
                  
                    <th pSortableColumn="Start_Date">
                      <div class="column-header">Start Date <p-sortIcon field="Start_Date"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="startDateOptions" [(ngModel)]="selectedStartDate" (onChange)="filterData()"
                                  placeholder="Select Date" [appendTo]="'body'" [showClear]="true" [filter]="true" filterPlaceholder="">
                      </p-dropdown>
                    </th>
          
                    
                    <th pSortableColumn="Stop_Date">
                      <div class="column-header">Stop Date <p-sortIcon field="Stop_Date"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="stopDateOptions" [(ngModel)]="selectedStopDate" (onChange)="filterData()"
                                  placeholder="Select Date" [appendTo]="'body'" [showClear]="true" [filter]="true" filterPlaceholder="">
                      </p-dropdown>
                    </th>
          
                    
                    <th pSortableColumn="Per_day_req">
                      <div class="column-header">Per Day Req(barrel) <p-sortIcon field="Per_day_req"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="perDayReqOptions" [(ngModel)]="selectedPerDayReq" (onChange)="filterData()"
                                  placeholder="Select req" [appendTo]="'body'" [showClear]="true" [filter]="true" filterPlaceholder="">
                      </p-dropdown>
                    </th>
          
                    
                    <th pSortableColumn="Type_of_water">
                      <div class="column-header">Type of Water <p-sortIcon field="Type_of_water"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="typeOfWaterOptions" [(ngModel)]="selectedTypeOfWater" (onChange)="filterData()"
                                  placeholder="Select Type" [appendTo]="'body'" [showClear]="true" [filter]="true" filterPlaceholder="">
                      </p-dropdown>
                    </th>
          
                    
                    <th pSortableColumn="Fulfillment">
                      <div class="column-header">Fulfillment <p-sortIcon field="Fulfillment"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="fulfillmentOptions" [(ngModel)]="selectedFulfillment" (onChange)="filterData()"
                                  placeholder="Select Ful" [appendTo]="'body'" [showClear]="true" [filter]="true" filterPlaceholder="">
                      </p-dropdown>
                    </th>
                  </tr>
                </ng-template>
          
              
                <ng-template pTemplate="body" let-item>
                  <tr>
                    <td>{{ item['fracCrew'] }}</td>
                    <td>{{ item['Pad_Name'] }}</td>
                    <td>{{ item['Start_Date'] }}</td>
                    <td>{{ item['Stop_Date'] }}</td>
                    <td>{{ item['Per_day_req'] }}</td>
                    <td>{{ item['Type_of_water'] }}</td>
                    <td>{{ item['Fulfillment'] }}</td>
                  </tr>
                </ng-template>
                
              </p-table>
            </div>
            <!-- <app-gantt-chart [dataSource]="'gen'"></app-gantt-chart> -->
          </p-tabPanel>
          <p-tabPanel header="Pond Summary">
            <div>
                  
              <p-table [value]="filteredPondSummary" [scrollable] ="true" scrollHeight="350px" class="custom-table">
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="Date" style="width: 20px;">
                      <div class="column-header">Date<p-sortIcon [field]="'Date'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="pondDateOptions" [(ngModel)]="selectedPondDate" (onChange)="filterData()"
                                  placeholder="Select Date" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Pond">
                      <div class="column-header">Pond<p-sortIcon [field]="'Pond'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="pondOptions" [(ngModel)]="selectedPond" (onChange)="filterData()"
                                  placeholder="Select Pond" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Qty">
                      <div class="column-header">Qty(barrel)<p-sortIcon [field]="'Qty'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="pondQtyOptions" [(ngModel)]="selectedPondQty" (onChange)="filterData()"
                                  placeholder="Select Qty" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="In">
                      <div class="column-header">In(barrel)<p-sortIcon [field]="'In'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="pondInOptions" [(ngModel)]="selectedPondIn" (onChange)="filterData()"
                                  placeholder="Select In" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Out">
                      <div class="column-header">Out(barrel)<p-sortIcon [field]="'Out'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="pondOutOptions" [(ngModel)]="selectedPondOut" (onChange)="filterData()"
                                  placeholder="Select Out" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Evap">
                      <div class="column-header">Evap(barrel)<p-sortIcon [field]="'Evap'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="pondEvapOptions" [(ngModel)]="selectedPondEvap" (onChange)="filterData()"
                                  placeholder="Select Evap" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Min">
                      <div class="column-header">Min(barrel)<p-sortIcon [field]="'Min'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="pondMinOptions" [(ngModel)]="selectedPondMin" (onChange)="filterData()"
                                  placeholder="Select Min" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Max">
                      <div class="column-header">Max(barrel)<p-sortIcon [field]="'Max'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="pondMaxOptions" [(ngModel)]="selectedPondMax" (onChange)="filterData()"
                                  placeholder="Select Max" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Type">
                      <div class="column-header">Type<p-sortIcon [field]="'Type'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="pondTypeOptions" [(ngModel)]="selectedPondType" (onChange)="filterData()"
                                  placeholder="Select Type" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                  <tr>
                    <td>{{ item.Date }}</td>
                    <td>{{ item.Pond }}</td>
                    <td>{{ item.Qty }}</td>
                    <td>{{ item.In }}</td>
                    <td>{{ item.Out }}</td>
                    <td>{{ item.Evap }}</td>
                    <td>{{ item.Min }}</td>
                    <td>{{ item.Max }}</td>
                    <td>{{ item.Type }}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Transaction All">
            <div>
                  
              <p-table [value]="filteredTransactionAll" [scrollable] ="true" scrollHeight="350px" class="custom-table">
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="Date">
                      <div class="column-header">Date<p-sortIcon [field]="'Date'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="dateOptions" [(ngModel)]="selectedDate" (onChange)="filterData()"
                                  placeholder="Select Date" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Source">
                      <div class="column-header">Source<p-sortIcon [field]="'Source'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="sourceOptions" [(ngModel)]="selectedSource" (onChange)="filterData()"
                                  placeholder="Select Source" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Destination">
                      <div class="column-header">Destination<p-sortIcon [field]="'Destination'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="destinationOptions" [(ngModel)]="selectedDestination" (onChange)="filterData()"
                                  placeholder="Select Destination" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Qty">
                      <div class="column-header">Qty(barrel)<p-sortIcon [field]="'Qty'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="qtyOptions" [(ngModel)]="selectedQty" (onChange)="filterData()"
                                  placeholder="Select Qty" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Type">
                      <div class="column-header">Type<p-sortIcon [field]="'Type'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="typeTransactionOptions" [(ngModel)]="selectedTransactionType" (onChange)="filterData()"
                                  placeholder="Select Type" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Cost">
                      <div class="column-header">Cost<p-sortIcon [field]="'Cost'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="costOptions" [(ngModel)]="selectedCost" (onChange)="filterData()"
                                  placeholder="Select Cost" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                  <tr>
                    <td>{{ item.Date }}</td>
                    <td>{{ item.Source }}</td>
                    <td>{{ item.Destination }}</td>
                    <td>{{ item.Qty }}</td>
                    <td>{{ item.Type }}</td>
                    <td>{{ '$' + item.Cost }}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </p-tabPanel>
          
        </p-tabView>
     
</div>
