import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from '../src/Routing/app-routing.module';

import { AppComponent } from '../src/components/app/app.component';

import { MainPageComponent } from './components/main-page/main-page.component';
import { HeaderComponent } from './components/header/header.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { TopNavBarComponent } from './components/top-nav-bar/top-nav-bar.component';
import { CalculateTabComponent } from './components/calculate-tab/calculate-tab.component';
import { SendDataComponent } from './components/send-data/send-data.component';
import { SequenceTableComponent } from './components/sequence-table/sequence-table.component';
import { OperationTableComponent } from './components/operation-table/operation-table.component'
import { BaseParameterComponent } from './components/base-parameter/base-parameter.component';
import { ParentComponent } from './components/parent/parent.component';
import { CapacityTableComponent } from './components/capacity-table/capacity-table.component';
import { ConnectionTableComponent } from './components/connection-table/connection-table.component';
import { AboutComponent } from './components/about/about.component';
import { LoginComponent } from './components/login/login.component';


import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { PaginatorModule } from 'primeng/paginator';
import { CalendarModule } from 'primeng/calendar';
import { TabViewModule } from 'primeng/tabview';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';

import { MsalModule, MsalService, MsalGuard, MsalInterceptor } from '@azure/msal-angular';
import { MsalBroadcastService } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { environment } from '../src/environments/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LogoutComponent } from './components/logout/logout.component';
import { GeneratorComponent } from './components/generator/generator.component';
import { HelpButtonComponent } from './components/help-button/help-button.component';
import { OptSequenceComponent } from './components/opt-sequence/opt-sequence.component';
import { GanttChartComponent } from './components/gantt-chart/gantt-chart.component';



@NgModule({
  declarations: [
    AppComponent,
    CalculateTabComponent,
    BaseParameterComponent,
    AboutComponent,
    SequenceTableComponent,
    OperationTableComponent,
    CapacityTableComponent,
    ConnectionTableComponent,
    ParentComponent,
    SendDataComponent,
    MainPageComponent,
    HeaderComponent,
    HomePageComponent,
    TopNavBarComponent,
    LoginComponent,
    LogoutComponent,
    GeneratorComponent,
    HelpButtonComponent,
    OptSequenceComponent,
    GanttChartComponent
   
    
  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatToolbarModule,
    MatTabsModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    MatSnackBarModule,
    HttpClientModule,
    ToastModule,
    TableModule,        
    RadioButtonModule,   
    InputTextModule,     
    ButtonModule,
    DropdownModule,
    PaginatorModule,
    CalendarModule,
    TabViewModule,
    ConfirmDialogModule,
    DialogModule,
    TooltipModule,
    
     MsalModule.forRoot(new PublicClientApplication({
      auth: {
        clientId: environment.root.clientId, 
        authority: `https://login.microsoftonline.com/${environment.root.tenantId}`, 
        redirectUri: environment.redirectUri 
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      }
    }),
    
     {
      interactionType: InteractionType.Redirect, 
      authRequest: {
        scopes: ['user.read'] 
      }
    }, {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map([['https://graph.microsoft.com/v1.0/me', ['user.read']]])
    })
  ],
  providers: [
    MessageService,
    ConfirmationService,
    MsalService,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalGuard
  
  ],
  
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
